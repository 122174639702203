<template>
    <b-container class="main-container">
        <h1>{{ $t('token_confirm') }}</h1>
        <p v-if="success === false">{{ $t('token_confirm_invalid') }}</p>
        <p v-else-if="success === true">{{ $t('token_confirm_successful_type_' + type) }}</p>
        <div v-else-if="type !== null">
            <p>{{ $t('token_confirm_type_' + type) }}</p>
            <b-button v-if="buttonDisabled === false" variant="success" class="mt-2" @click="patchToken">{{ $t('token_confirm_button') }}</b-button>
            <b-button v-else type="submit" class="mt-2" variant="success" disabled="disabled">
                <b-spinner class="" small label="Large Spinner"></b-spinner>
            </b-button>
        </div>
        <div v-else><b-spinner large></b-spinner></div>
    </b-container>
</template>

<script>
import ApiService from '../../apiService'

export default {
  name: 'ConfirmToken',
  mounted () {
    this.checkConfirmToken()
  },
  data () {
    return {
      success: null,
      type: null,
      buttonDisabled: false
    }
  },
  methods: {
    checkConfirmToken () {
      ApiService.checkConfirmToken(this.$route.params.token).then(resp => {
        const type = resp.data.data.type
        if (type === 1) {
          this.type = 'email'
        } else {
          this.success = false
        }
      }).catch((e) => {
        this.success = false
      })
    },
    patchToken () {
      this.buttonDisabled = true
      ApiService.patchConfirmToken(this.$route.params.token).then((resp) => {
        localStorage.setItem('accessToken', resp.data.data.token)
        localStorage.setItem('refreshToken', resp.data.data.refresh_token)
        this.processJwt()
        this.success = true
      }).catch((e) => {
        this.success = false
        this.buttonDisabled = false
      })
    }
  }
}
</script>
